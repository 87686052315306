import React, { useCallback, useEffect, useRef, useState } from "react";
import platformJSON from "../../store/platforms.json";

import {
  Box,
  Link,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel, Grid,
  InputLabel,
  MenuItem, OutlinedInput, TextField, Typography
} from "@mui/material";
import { Prompt } from "../../components/Prompt";
import { makeStyles } from "@mui/styles";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { other } from "../../store/formContent";
import { InputField } from "../../components/InputField";
import { InfoToolTip } from "../../components/InfoTooltip";
import { PlatformField } from "../../components/PlatformField";
import { CheckboxField } from "../../components/CheckboxField";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10),
    direction: "row"
  }

}));


const Platform3 = (props) => {

  const classes = useStyles();
  const { slideId, activeField, setActive, lastPrompt, setValidationList, lastUsedValues } = props;
  const {
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
    control,
    reset,
    formState: { errors }
  } = useFormContext();

  //const watchFields = watch(["cookieSelect", "contextualSelect", "platforms", "platforms.Other.checked"]);
  const [watchedCookie, watchedContextual, watchedNext, watchedPlatforms, watchedOther] = useWatch({ name: ["cookieSelect", "contextualSelect","nextSelect", "platforms", "platforms.Other.checked"] });
  const platformDetails = useRef(platformJSON.platforms);
  const liverampPlatforms = useRef(platformJSON.liverampPlatforms);

  useEffect(() => {

    /* let platformFields = [];
     platforms.every((platform) => {
       platformFields.push(`platforms.${platform}.cookie`);
       platformFields.push(`platforms.${platform}.contextual`);
       return true;
     });
     console.log("platformFields");
     console.log(platformFields);
 */
    if (activeField === slideId) {
      setValidationList(["platforms.Other.checked"]);
    }
  }, [activeField]);


  //At least one Platform, with at least 1 id and all selected platforms have values for whichever type(s) have been selected in Reach2
  const hasPlatformIds = () => {
    let hasChecked = false;
    let noMissingValues = true;

    if (watchedPlatforms) {
      const keys = Object.keys(watchedPlatforms);

      keys.every((key) => {

        if (key === "Other") {
          if (watchedOther) {
            if (!watchedPlatforms[key].request) {
              setError(`platforms.${key}.request`, { type: "custom", message: `Please enter a value for ${key}` });
              noMissingValues = false;
            } else {
              hasChecked = true;
            }
          }
        } else {
          if (watchedPlatforms[key].checked) {
            if (watchedCookie) { //Cookie
              if (!watchedPlatforms[key].cookie) {
                setError(`platforms.${key}.cookie`, {
                  type: "custom",
                  message: `Please enter a value for ${key} cookie`
                });
                noMissingValues = false;
              } else {
                hasChecked = true;
              }
            }
            /*if (watchedContextual) { //Contextual
              if (!watchedPlatforms[key].contextual) {
                setError(`platforms.${key}.contextual`, {
                  type: "custom",
                  message: `Please enter a value for ${key} contextual`
                });
                noMissingValues = false;
              } else {
                hasChecked = true;
              }
            }*/
            if (watchedNext) { //Contextual
              if (!watchedPlatforms[key].next) {
                setError(`platforms.${key}.next`, {
                  type: "custom",
                  message: `Please enter a value for ${key} next`
                });
                noMissingValues = false;
              } else {
                hasChecked = true;
              }
            }
          }
        }
        return true;
      });
    }
    return hasChecked && noMissingValues;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Prompt prompt={"Select the DSP(s) you will be using for this audience"} index={3} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="textPrimary" mb={5} mt={5} fontSize={18}>
          To provision an audience to a chosen DSP, please provide your respective ID (i.e. partner, seat, advertiser or
          other). If you’re unsure, please put "unknown" in the ID space and we will assist after receiving your
          audience request.
        </Typography>
      </Grid>

      {/* Directly Integrated DSPs*/}

      <Grid item xs={12} mb={3}>
        <Typography variant="body1" color="textPrimary" ml={4} mb={3} mr={4} fontSize={20}
                    sx={{ borderBottom: "2px solid", borderColor: "primary.dark" }}>Directly integrated
          DSPs</Typography>
        <FormGroup sx={{ display: "grid", rowGap: 4, gridTemplateColumns: "repeat(5, 1fr)", borderTop: "2" }}>
          {platformDetails.current.map((row) => {
            const platform = row.name;

            const watched = watch([`platforms.${platform}.checked`]);
            const cookiePlaceholder = row.cookieName ? row.cookieName : "Seat ID";
            const contextualPlaceholder =  row.contextualName ? row.contextualName : "PMP Seat ID";
            const nextPlaceholder = row.contextualName ? row.contextualName : "Partner ID";

            //change `platforms.${platform}.checked` to platformValues
            return (
              <Box key={platform}>
                <FormControlLabel
                  control={
                    <Controller
                      name={`platforms.${platform}.checked`}
                      control={control}
                      render={({ field: { onChange, value, ref, ...field } }) => (
                        <Checkbox
                          onChange={() => {
                            onChange(!value);
                          }}
                          inputRef={ref}
                          checked={!!value}
                        />

                      )}
                    />
                  }
                  label={<Typography variant="h6" color="textPrimary"> {platform} </Typography>}
                />
                <Box sx={{ flexDirection: "row", ml: 3, display: watched[0] === true ? "flex" : "none" }}>
                  <PlatformField label={"Cookie"} placeholder={cookiePlaceholder} display={!!watchedCookie} id={`platforms.${platform}.cookie`} name={`platforms.${platform}.cookie`} control={control} />
                  <PlatformField label={"Contextual"} placeholder={contextualPlaceholder} display={!!watchedContextual} id={`platforms.${platform}.contextual`} name={`platforms.${platform}.contextual`} control={control} />
                  <PlatformField label={"NEXT"} placeholder={platform === "The Trade Desk" ? "Partner Id" : nextPlaceholder} display={!!watchedNext} id={`platforms.${platform}.next`} name={`platforms.${platform}.next`} control={control} />
                </Box>
              </Box>
            );
          })}

        </FormGroup>
      </Grid>

      {/*LiveRamp Enabled DSPs*/}
      <Grid item xs={12}>
        <Typography variant="body1" color="textPrimary" ml={4} mb={2} mr={4} fontSize={22}
                    sx={{ borderBottom: "2px solid", borderColor: "primary.dark" }}>LiveRamp Enabled DSPs</Typography>
        <FormGroup sx={{ display: "grid", rowGap: 4, gridTemplateColumns: "repeat(5, 1fr)" }}>
          <Box gridColumn="span 5">
            <Typography variant="body1" color="textSecondary" mb={0} fontSize={16}>
              Platforms marked with an "*" require a direct relationship with LiveRamp or an agreement through a
              reseller. If you need a reseller agreement, please fill out this <Link
              href={"https://liveramp.com/lp/consumer-social/contact-us/"}
              target={"_blank"} underline={"hover"} rel="noopener">Direct Intake Form</Link>

            </Typography>
          </Box>
          {liverampPlatforms.current.map((row) => {
            const platform = row.name;
            const watched = watch([`platforms.${platform}.checked`]);

            const cookiePlaceholder = row.cookieName ? row.cookieName : "Seat ID";
            const contextualPlaceholder =  row.contextualName ? row.contextualName : "PMP Seat ID";
            const nextPlaceholder =  row.contextualName ? row.contextualName : "Partner ID";

            const asterisk = row.isLiveRamp === "required" ? "* " : "";
            return (
              <Box key={platform}>
                <FormControlLabel
                  control={
                    <Controller
                      name={`platforms.${platform}.checked`}
                      control={control}
                      render={({ field: { onChange, value, ref, ...field } }) => (
                        <Checkbox
                          onChange={() => {
                            onChange(!value);
                          }}
                          inputRef={ref}
                          checked={!!value}
                        />
                      )}
                    />
                  }
                  label={<Typography variant="h6" color="textPrimary">{`${asterisk}${platform}`}</Typography>}
                />
                <Box sx={{ flexDirection: "row", ml: 3, display: watched[0] === true ? "flex" : "none" }}>
                  <PlatformField label={"Cookie"} placeholder={cookiePlaceholder} display={!!watchedCookie} id={`platforms.${platform}.cookie`} name={`platforms.${platform}.cookie`} control={control} />
{/*
                  <PlatformField label={"Contextual"} placeholder={contextualPlaceholder} display={!!watchedContextual} id={`platforms.${platform}.contextual`} name={`platforms.${platform}.contextual`} control={control} />
*/}
                  <PlatformField label={"NEXT"} placeholder={nextPlaceholder} display={!!watchedNext} id={`platforms.${platform}.next`} name={`platforms.${platform}.next`} control={control} />
                  {/*sx={{ marginLeft: watchedCookie ? 2 : 0 }}*/}
                </Box>
              </Box>
            );
          })}

          <Box key={`Other`} sx={{ gridColumn: "span 5" }} mt={10}>
            <CheckboxField name={"platforms.Other.checked"} label={"Other / Request DSP"} rules={{validate:hasPlatformIds}}/>
            {/*<FormControlLabel
              control={
              <CheckboxField control={control} name={'platforms.Other.checked'} rules={{validate:hasPlatformIds}} />

              }
              label={<Typography variant="h5" color="textPrimary">Other / Request DSP</Typography>}
            />*/}
            <Box sx={{
              width: "500px",
              ml: 3,
              display: watchedOther === true ? "block" : "none"
            }}>
              <Typography component="h1" variant="subtitle2" color="textSecondary">Please enter the DSP or Platform for
                the audience you are creating ex. Facebook, Adelphic, etc...</Typography>
              <InputField {...other} control={control} setActive={setActive} slideId={slideId} />
            </Box>
          </Box>
        </FormGroup>
      </Grid>
      {
        (errors.platforms && <FormHelperText id="component-error-text" sx={{}}
                                               error>{"Please input at least one Platform ID and fill out each field of the Platform(s) selected"}</FormHelperText>)}
    </Grid>
  );


};

export { Platform3 };