import { Prompt } from "../../components/Prompt";
import {
  Box, Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid, IconButton, Paper,
  Radio,
  RadioGroup, Stack, Tab, Tabs, TextareaAutosize, TextField, ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Controller, useFormContext } from "react-hook-form";
import { keywordText } from "../../store/formContent";
import { InputField } from "../../components/InputField";
import {comments} from "../../store/formContent"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    //justifyContent: "center",
    alignItems: "center",
    //justifyItems: "center",
    padding: theme.spacing(10),
    direction: "column"
  }

}));

const Info4 = (props) => {

  const classes = useStyles();
  const { slideId, activeField, setActive, loginStatus, setValidationList, children } = props;

  const { control, formState: { errors } } = useFormContext();
  useEffect(() => {
    if (activeField === slideId) {
      setValidationList([]);
    }
  }, [activeField]);
  // const [selectedCard, setSelectedCard] = useState(1);
  /*
    const handleCardClick = (e, value) => {
      console.log(value);
      console.log(e.target.id);
    };*/

  return (
    <Stack className={classes.root} spacing={10}>
      <Prompt prompt={"Anything else we should know?"} index={4} />
{/*
      <InputField sx={{ width: "80vw", maxWidth:"1000px", border: 1 }} {...comments} control={control} slideId={slideId}/>
*/}
     <Controller
        id={"comments"}
        name={"comments"}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <TextField variant="filled" sx={{ width: "80%", border: 1 }}
                     placeholder="Questions/Comments"
                     multiline={true}
                     rows={10}
                     value={value}
                     inputRef={ref}
                     type={"text"}
                     onChange={onChange}
          />
        )} />
      {loginStatus &&
      <Button
        className={classes.Submit}
        type="submit"
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
      }
    </Stack>
  );
};

export { Info4 };