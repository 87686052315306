import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Grid  sx={{ marginTop:"75px" }} className={classes.root}>
      <h1>Page Not Found</h1>
      <p>
        <b>Looks like something went wrong. Please try again later.</b>
      </p>
      <p>
        Contact us at <b>contact@crosspixel.net</b> with any questions.
      </p>
    </Grid>
  );
};

export default NotFound;
