import { makeStyles } from "@mui/styles";
import { Container, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50vw",
    marginBottom: "30px",
    justifyContent: "space-between",
    "& h3": {
      fontWeight: 800,
      marginTop: "30px !important",
      textTransform: "uppercase",
      textAlign: "center"
    },
    "& h4": {
      fontWeight: 800,
      marginBottom: "20px !important",
      marginTop: "20px !important",
      textTransform: "uppercase"
    }
  },
  
}));

const AboutUsHero = () => {
  const classes = useStyles();

  return (
    <Container sx={{ marginTop: "75px" }} className={classes.root}>
      <Stack spacing={2}>
        <Typography color="primary.dark" variant="h3">
          Introducing Hero Data
        </Typography>

        <Typography variant="h5" sx={{textTransform: "uppercase",
          textAlign: "center", marginBottom: "50px !important"}} >
          Empowering Programmatic Advertising with Multicultural
          Targeting
        </Typography>

        <Typography variant="h4">
          About Hero Data
        </Typography>

        <Typography>
          Hero Data is a cutting-edge behavioral and contextual data provider that specializes in
          reaching multicultural audiences. Developed by Hero Media, a multicultural media
          company with a deep understanding of this segment, Hero Data leverages the expertise
          of our expertise to deliver precise targeting capabilities, allowing advertisers to
          effectively reach multicultural audiences across all programmatic advertising channels.
        </Typography>

        <Typography variant="h4">
          Our Targeting Solutions
        </Typography>

        <Typography>
          Hero Data offers two powerful types of targeting data for programmatic advertising:
          behavioral data for audience targeting and contextual data for page-level targeting. With
          over 200 pre-built audiences readily available and the ability to create custom
          audiences in most multicultural categories within 3-4 days, we provide comprehensive
          solutions tailored to your advertising needs.
        </Typography>

        <Typography variant="h4">
          Our Methodology
        </Typography>

        <Typography variant="h5">
          Behavioral Data
        </Typography>

        <Typography>
          Hero Data tracks and monitors the web surfing activity and behaviors of over 450 million
          users in North America and Latin America. Our platform identifies users based on their
          search and research activities for products and services using keywords that indicate
          strong interest. With decades of domain experience, Hero Data combines this data with
          a unique understanding of cultural, social, and political trends that influence the
          multicultural market. This allows us to create the most accurate audiences in the
          industry. Users are seamlessly added to relevant audience segments, enabling targeted
          ads as they browse the web, use apps, and engage with CTV.
        </Typography>

        <Typography variant="h5">
          Contextual Data
        </Typography>

        <Typography>
          Hero Data creates page-level contextual audiences, enabling marketers to reach their
          desired audience based on the web pages and apps they visit. Leveraging our
          unparalleled understanding of the multicultural marketplace, we identify true context and
          meaning that resonate with multicultural audiences. This ensures that marketers can
          effectively connect with users based on genuine cultural relevance.
        </Typography>

        <Typography variant="h4">
          Our Partners
        </Typography>

        <Typography>
          Hero Data has established partnerships with over 40 DSPs and leading industry
          partners, including TheTradeDesk, Google, Yahoo, and Verizon. Through these
          integrations, we enable seamless activation across various media channels and
          platforms. For a comprehensive list of our partners, please visit our online audience
          submission platform at <Link href={"#"}>www.hero.crsspxl.com</Link>.
        </Typography>

        <Typography variant="h4">
          How to Activate an Audience
        </Typography>

        <Typography>
          Activating your desired audience with Hero Data is a streamlined process. Visit our
          online audience submission form at <Link href={"#"}>www.hero.crsspxl.com</Link> to discover and submit your
          audience. Choose from our extensive range of over 200 prebuilt segments or request a
          custom audience. Enter your DSP and Seat ID, and within 3-4 business days, your
          audience will be available for precise targeting.
        </Typography>

        <Typography sx={{ marginTop: "75px" }} color="primary.dark" variant="h3">
          Join the Hero Data Revolution
        </Typography>

        <Typography>
          Transform your programmatic advertising strategy by harnessing the power of
          multicultural targeting with Hero Data. With our unrivaled insights and precision
          targeting capabilities, you can authentically connect with diverse audiences, drive
          engagement, and achieve exceptional campaign performance. Be a part of shaping a
          diverse and inclusive advertising landscape. Discover the power of Hero Data today!
        </Typography>


      </Stack>

    </Container>

);
};

export {AboutUsHero};
