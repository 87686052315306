import { Box, Stack, Typography } from "@mui/material";
import logo from "../../static/logo.png";
import logo2 from "../../static/CrossPixel-logo-notagline-300x68.png";
import { Image } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { Slide, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({

  Header: {
    paddingTop: theme.spacing(5),
    maxWidth: "1000px",
    width: "70%",
    color: theme.palette.text.primary
  },
  Subtitle: {
    paddingTop: theme.spacing(5),
    maxWidth: "1000px",
    width: "50%",
    color: theme.palette.text.secondary
  }
}));


const Intro0 = (props) => {

  const classes = useStyles();
  const { slideId, activeField, setActive, lastPrompt, setValidationList, children } = props;


  useEffect(() => {
    if (activeField === slideId) {

        const notify = () => toast.info('Checkout Crosspixel Next', {
          position: "top-center",
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });

        /*const shownBanner = localStorage.getItem("banner");
        if (shownBanner !== "true" ) {
          localStorage.setItem("banner", "true");
          notify();
        }*/

        //notify();
      setValidationList([]);
    }
  }, [activeField]);

  return (
    <Stack
         sx={{ height: "80vh", pb:5, m:0 }}
         direction="column"
         justifyContent="center"
         alignItems="center"
         textAlign="center"
        >
      <Box
        component="img"
        sx={{
          height: 68,
          width: 300
        }}
        src={logo2}
      />
      <Typography variant={"h4"} className={classes.Header} sx={{fontWeight: '100'}}>
        Welcome to Treble from Cross Pixel
      </Typography>

      <Typography variant={"h6"} className={classes.Header} sx={{fontWeight: '100'}}>
        Please use this website to place orders for behavioral and contextual custom audiences.
        Please note that NEXT, our advanced contextual audiences, are now fully integrated
        and accessible in the contextual area of The Trade Desk.
      </Typography>


    </Stack>

  );
};

export
{
  Intro0
};