import { Box, Button, Container, Grid, Stack, Typography, useTheme } from "@mui/material";
import { CheckCircleSharp, TrendingFlat } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import React, { Children, cloneElement, useEffect } from "react";
import { Recaptcha } from "./Recaptcha";
import { Prompt } from "./Prompt";

/*
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
  },

  div: {
    /!*width: "90vh",*!/
    /!*border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "16px",*!/
  },
  div2: {},
  spacing: {
    //paddingTop: theme.spacing(20)
  },
  children: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  },
  input: {
    margin: theme.spacing(1),
    width: "50vh"
  }
}));
*/

const useStyles = makeStyles((theme) => ({
  GridSlide: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    direction: "row",

    /*display: "flex",
    alignContent:"center",
    alignItems: "center",
    direction: "column",
     */
  }
}));

const Slide = ({ prompt, id, children }) => {

  const classes = useStyles();

  /* //const error = this.state.valid ? "" : "error";
   const classNames = `${classes.div}`;
   const theme = useTheme();

   children = Children.map(children, el => {
     //Nesting setActive any other way seems to break it?
     return cloneElement(el, { slideId: id, setActive: setActive, lastPrompt: lastPrompt });
   });*/

  //const rowSpacing = 10/children.length ;

  return (
    <Stack  className={classes.GridSlide} sx={{pt:10, pb:2,mt:0}}>
      <Prompt prompt={prompt} index={id} />
      {children}
    </Stack>

  );
};
export { Slide };