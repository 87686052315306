import { Prompt } from "../../components/Prompt";
import {
  Box, Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid, IconButton, InputAdornment, Paper,
  Radio,
  RadioGroup, Stack, Tab, Tabs, TextareaAutosize, TextField, ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { BasicFormField } from "../../components/BasicFormField";
import { company, email, fullname, passwordOptional, phone, confirmOptional } from "../../store/formContent";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { Recaptcha } from "../../components/Recaptcha";
import { Slide } from "../../components/Slide";



const useStyles = makeStyles((theme) => ({
  root: {
    //justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    justifyItems: "center",
    //padding: theme.spacing(5),
    direction: "row",
    textAlign: "center",
    width: "500px",
    height: "100vh",
    marginTop:theme.spacing(10)

},
  Submit: { //Because can't resize g-captcha iframe other than using transform, currently easier just to make submit button the same size manually
    height: "60px",
    width: "234px"
  },
  Box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"

  }
  /*ele: {
    width:"100%",
    height:"100%"
  }*/

}));

const User5 = (props) => {
  const classes = useStyles();
  const { slideId,activeField, setActive, lastPrompt, setValidationList } = props;
  const { setError, getValues, control, formState: { errors }, unregister } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState("");

  useEffect(() => {
    if (activeField === slideId) {
      console.log("Active field5:" + activeField);
      setValidationList([]);
    }
  }, [activeField]);

  const verifyCaptcha = (response) => {
    console.log(response);
    setCaptchaResponse(response);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value) => {
    const passValues = getValues(['passwordOptional','confirmOptional']);
    if (passValues[0] !== passValues[1]) {
      return  "Passwords don't match";
    }
  }

  /* useEffect(() => {
     console.log("USER5")
       console.log(errors.Recaptcha);
       console.log(errors);
   }, [errors]);*/


  // const [selectedCard, setSelectedCard] = useState(1);
  /*
    const handleCardClick = (e, value) => {
      console.log(value);
      console.log(e.target.id);
    };*/

  //TODO: ADD PROCESS.ENV.isHERO to change copy with Treble below
  return (
    <Stack className={classes.root} spacing={3}>
      <Prompt prompt={"Enter your contact information"} index={5} />

        <BasicFormField {...fullname} control={control} unregister={unregister} />
        <BasicFormField {...company} control={control} unregister={unregister}  />
        <BasicFormField {...email} control={control} unregister={unregister} />
        {/*<BasicFormField {...phone} control={control} unregister={unregister} />*/}
        <Prompt prompt={"Want to create an account?"} />
        <Typography component="h1" variant="subtitle2" sx={{ display: "block" }}>
          By creating an account all of your seat information and account details will be stored so you don’t have to
          reenter them next time you use Treble.
        </Typography>
        <BasicFormField {...passwordOptional}
                        unregister={unregister}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        type={showPassword ? "text" : "password"}
                        control={control} />
        <BasicFormField {...confirmOptional}
                        unregister={unregister}
                        rules={{validate: validatePassword}}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        type={showPassword ? "text" : "password"}
                        control={control} />
        <Recaptcha captchaResponse={setCaptchaResponse} />
        <Button
          className={classes.Submit}
          type="submit"
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </Stack>
  );
};

export { User5 };