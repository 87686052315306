import { Slide } from "../../components/Slide";
import { Prompt } from "../../components/Prompt";
import {
  Box, Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia, FormHelperText,
  Grid, IconButton, Paper,
  Radio,
  RadioGroup, Stack, Tab, Tabs, TextareaAutosize, TextField, ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { FileUploadOutlined } from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { TabPanel } from "../../components/TabPanel";
import { useFormContext } from "react-hook-form";
import { BasicFormField } from "../../components/BasicFormField";
import { audienceName, keywordText, keywordUrl } from "../../store/formContent";
import { InputField } from "../../components/InputField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyItems: "center",
    //padding: theme.spacing(10),
    direction: "row",
    marginTop: theme.spacing(10),
    height: "100vh"


  },
  audience: {
    minWidth: "365px",
    width: "40vw",
    minHeight: "300px",
    padding: theme.spacing(1),
    textAlign: "center"
  },
  CardMain: {
    color: theme.primary
  },
  CardToggle: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  }
}));


const Audience1 = (props) => {
  const classes = useStyles();
  const { slideId, activeField, setActive, lastPrompt, loginStatus, setValidationList } = props;
  const {
    register,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
    onFocus,
    formState: { errors }
  } = useFormContext();

  //const [selectedCard, setSelectedCard] = useState(1);
  const [selectedTab, setSelectedTab] = useState("File");

  // Fields
  const [file, setFile] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [url, setUrl] = useState("");
  const [keywords, setKeywords] = useState("");
  const [name, setName] = useState("");

  const fileTypes = ["txt", "csv", "xls", "xlsx", "doc", "docx"];

  const hasKeywordInput = (value) => {
    const keywordValues = getValues(["fileUpload", "keywordText", "keywordUrl"]);
    if ((!keywordValues[0] || keywordValues[0].length < 1) && !keywordValues[1] && !keywordValues[2]) {
      return false;
    } /*else if (keywordValues.keywordUrl && (!keywordValues[0] && !keywordValues[1])) {

    }*/
  };

  useEffect(() => {
    if (activeField === slideId) {
      setValidationList(["audienceName", "keywordText"]);
    }
  }, [activeField]);


  /* const handleCardClick = (e, value) => {
     console.log(value);
     console.log(e.target.id);
   };*/

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
  };

  const handleSetFile = (f) => {
    setFileError(false);
    setFile(f);
    setValue("fileUpload", f);
    clearErrors("keywordText");
    clearErrors("keywordUrl");
  };

  const handleFileError = (err) => {
    console.log(err);
    setFileError(true);
  };

  return (
    <Stack className={classes.root} spacing={5}>
      <Prompt prompt={"Enter your audience information"} index={1} />

      {/*
      //Might use later on
      //TODO: Center this card /textAlign
      <Card variant="outlined" sx={{ maxWidth: 345, backgroundColor: selectedCard === 1 ? 'primary.dark' : '' }}>
        <CardActionArea id={1} onClick={handleCardClick}>
          <CardContent className={classes.CardMain}>
            <Typography variant="h4" component="div">
              Custom
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>*/}

      {/*
      <TextField sx={{ width: "50%" }}
                 label={"Audience Name"}
                 placeholder="ex. Lorem Ipsum"
                 value={name}
                 onChange={handleSetName}
                 InputLabelProps={{ shrink: true }}
      />*/}

      <InputField {...audienceName} control={control} setActive={setActive} slideId={slideId} />

      <Prompt sx={{ fontSize: 14 }} prompt={" Please submit the keywords you will be using for this campaign"} />

      <Paper className={classes.audience} elevation={2}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {<Tab value={"File"} label="Upload File" />}
          <Tab value={"Text"} label="Enter Keywords" />
          <Tab value={"Url"} label="Don’t Have Keywords?" />
        </Tabs>
        <TabPanel tabId={selectedTab} value={"File"}>
          {loginStatus &&
          <Box>
            <Paper elevation={file ? 3 : 10}
                   sx={{ marginBottom: "1em", backgroundColor: file && file.name ? "success.dark" : "" }}>
              <Typography variant={"overline"}
                          sx={{ color: "text.secondary" }}>{file && file.name ? `Uploaded: ${file.name}` : "( No File Uploaded )"}</Typography>
            </Paper>
            {
              <Box sx={{ display: file.name ? "none" : "block" }}
                   className={`FileUpload  ${fileError ? "FileError" : ""}`} {...register("fileUpload")}>
                <FileUploader maxSize={4} multiple={false} onTypeError={handleFileError} onSizeError={handleFileError}
                              handleChange={handleSetFile} name="file" types={fileTypes} />
              </Box>
            }
          </Box>
          }
          {
            !loginStatus && <Paper elevation={file ? 3 : 10}
                                   sx={{ marginBottom: "1em", backgroundColor: "" }}>
              <Typography variant={"overline"}
                          sx={{ color: "text.secondary" }}>{"Please Login to Upload Files "}</Typography>
            </Paper>
          }
        </TabPanel>
        <TabPanel tabId={selectedTab} value={"Text"}>
          <InputField {...keywordText} control={control} setActive={setActive} slideId={slideId}
                      rules={{ validate: hasKeywordInput }} />
        </TabPanel>
        <TabPanel style={{ textAlign: "left" }} tabId={selectedTab} value={"Url"}>
          <Typography sx={{ color: "text.secondary" }} variant={"body2"} pb={1}>
            Enter the URL of the product or service for this campaign and we will create an audience for you
          </Typography>
          <InputField {...keywordUrl} control={control} setActive={setActive} slideId={slideId} />
        </TabPanel>
      </Paper>
      {errors.keywordText &&
      (<FormHelperText id="component-error-text" sx={{}}
                       error>{"Please input one of the three options"}</FormHelperText>)}
    </Stack>
  );
};

export { Audience1 };