import { Prompt } from "../../components/Prompt";
import { Box, FormHelperText, Stack, Typography, Button, Divider, Tabs, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useFormContext } from "react-hook-form";
import { TableView } from "../../components/TableView";
import { InfoToolTip } from "../../components/InfoTooltip";
import { segment } from "../../store/formContent";
import segmentJSON from "../../store/segments.json";
import platformJSON from "../../store/platforms.json";
import { grey } from "@mui/material/colors";
import Link from "@mui/material/Link";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyItems: "center",
    //padding: theme.spacing(10),
    direction: "row",
    marginTop: theme.spacing(10),
    height: "100vh"


  },
  audience: {
    minWidth: "365px",
    width: "40vw",
    minHeight: "300px",
    padding: theme.spacing(1),
    textAlign: "center"
  },
  CardMain: {
    color: theme.primary
  },
  CardToggle: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  }
}));


const Segment1 = (props) => {
  const classes = useStyles();
  const { slideId, activeField, setActive, lastPrompt, setValidationList } = props;
  const {
    register,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
    onFocus,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    if (activeField === slideId) {
      setValidationList(["hasSegment"]);
    }
  }, [activeField]);

  const [segments, setSegments] = useState(segmentJSON.segments);
  const [filteredSegments, setFilteredSegments] = useState(segmentJSON.segments);
  const [selected, setSelected] = useState({});
  const [valueTab, setValueTab] = React.useState(0);

  const hasSegmentIds = () => {
    return selected && Object.keys(selected).length > 0;
  };

  const filterSegmentsFunc = (subnav) => {
    if(subnav !== 'All'){
      setFilteredSegments(segments.filter(segment => segment.name.includes(subnav)));
    } else {
      setFilteredSegments(segments)
    }
  };

  useEffect(() => {
    setValue("segments", selected);
  }, [selected]);

  //Fetch segments from db
  /*useEffect(() => {
    setSegments(segmentJSON.segments)
  }, []);*/

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <Stack className={classes.root} spacing={5}>
      <Prompt prompt={"What audience(s) are you interested in?"} index={1}>

      </Prompt>
      <Box sx={{ display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
      }}>
        <Tabs
          value={valueTab}
          onChange={handleChange}
          centered
        >
          <Tab onClick={() => filterSegmentsFunc('All')} label="All" />
          <Tab onClick={() => filterSegmentsFunc('African American')} label="African American" />
          <Tab onClick={() => filterSegmentsFunc('Latino')} label="Latino" />
          <Tab onClick={() => filterSegmentsFunc('Multicultural')} label="Multicultural" />
        </Tabs>
        {/*<Button sx={{ minWidth: 150 }} onClick={() => filterSegmentsFunc('All')}>All</Button>
        <Divider orientation="vertical" flexItem />
        <Button sx={{ minWidth: 150 }} onClick={() => filterSegmentsFunc('African American')}>African American</Button>
        <Divider orientation="vertical" flexItem />
        <Button sx={{ minWidth: 150 }} onClick={() => filterSegmentsFunc('Latino')}>Latino</Button>
        <Divider orientation="vertical" flexItem />
        <Button sx={{ minWidth: 150 }} onClick={() => filterSegmentsFunc('Multicultural')}>Multicultural</Button>*/}
      </Box>

      <div {...register(`hasSegment`, { validate: hasSegmentIds })} >
        <TableView {...segment} setSelectionModel={setSelected}
                   allRows={filteredSegments} error={errors}
                   control={control} />
      </div>
      <Button
        size="large"
        target="_top"
        rel="noopener noreferrer"
        href={`mailto:support@crosspixel.net`}
        style={{textTransform: 'none !important'}}
      >
        <Typography variant="button" style={{ fontSize: '1rem', color: '#FFFFFF', textTransform: 'none' }}>
          Not seeing the audience you're looking for? Contact us to request a custom audience
        </Typography>
      </Button>
      {errors.hasSegment &&
      (<FormHelperText id="component-error-text" sx={{}}
                       error>{"Please select at least one audience"}</FormHelperText>)}
    </Stack>
  );
};

export { Segment1 };