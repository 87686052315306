import { Box, Typography } from "@mui/material";
import { TrendingFlat } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { InfoToolTip } from "./InfoTooltip";
import React from "react";

const useStyles = makeStyles((theme) => ({

  typography: {
    /*  paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(5)*/
    textAlign: "center"
  }
}));

const Prompt = ({ prompt, index, children }) => {
  const classes = useStyles();

  return (
    <Typography sx={{justifySelf:"flex-start"}} className={classes.typography} variant="h4">
      {index && (
        <>
          {index}
          <TrendingFlat color="primary" />
        </>)}
      {prompt}
      {children}
    </Typography>
  );
};

export { Prompt };