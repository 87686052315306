import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
    maxWidth:"400px",
    width: "90%"
  },
  spacing: {
    /*
        paddingTop: theme.spacing(10)
    */
  }
}));

const InputField = ({
                      slideId,
                      setActive,
                      index,
                      id,
                      name,
                      control,
                      defaultValue,
                      label,
                      rules,
                      inputProps,
                      InputProps,
                      addSpacing,
                      prompt,
                      ...other
                    }) => {
  const classes = useStyles();

  const { clearErrors } = useFormContext();

  //const setActive = useContext(ScrollerContext);
  return (
    <Controller
      id={id}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <TextField className={classes.input}
                   label={label}
                   variant="filled"
                   value={value}
                   onChange={(e) => {
                     onChange(e);
                     clearErrors();
                   }}
                   error={!!error}
                   helperText={error ? error.message : null}
                   inputProps={inputProps}
                   InputProps={InputProps}
                   inputRef={ref}
                   onFocus={(e) => {
                     /*console.log("SLIDE ID: " + slideId);
                     if (!!error) {
                       console.log(error);
                       setActive(slideId);
                     }*/
                   }}
                   onBlur={(e) => {
                     //clearErrors();
                     onBlur(e);
                   }}
                   {...other}
        />
      )}
      rules={rules}
    />
  );
};

export { InputField };
