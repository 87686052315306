import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { InfoTwoTone, TrendingFlat } from "@mui/icons-material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from "@mui/styles";

const isHero = process.env.REACT_APP_HERO === "true";

const useStyles = makeStyles((theme) => ({
  typography: {
    textAlign: "center"
  }
}));

const InfoToolTip = ({ info }) => {
  const classes = useStyles();

  return (
    (isHero && <Tooltip disableFocusListener title={info}>
        <InfoOutlinedIcon />
    </Tooltip>)

  );
};

export { InfoToolTip };