import { InputAdornment, Button, Typography, Grid, Alert, AppBar, Toolbar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormProvider, useForm } from "react-hook-form";
import { Scroller } from "../components/Scroller";
import React, { useCallback, useEffect, useState } from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import { useNavigate } from "react-router-dom";

import { Segment1 } from "./slides/Segment1";
import { Audience1 } from "./slides/Audience1";
import { Reach2 } from "./slides/Reach2";
import { Platform3 } from "./slides/Platform3";
import { Info4 } from "./slides/Info4";
import { User5 } from "./slides/User5";
import { Intro0 } from "./slides/Intro0";
import { Hero0 } from "./slides/Hero0";
import CrosspixelNext from "../pages/CrosspixelNext.js"
const isHero = process.env.REACT_APP_HERO === "true";


const Form = ({ loginStatus, setLoginStatus }) => {

  const [interstitial, setInterstitial] = useState(null)


  let methods = useForm();
    //const [lastUsedPlatforms, setLastUsedPlatforms] = useState({});

    const values = useCallback(async () => {

      const body = {
        account: { token: localStorage.token, email: localStorage.email }
      };

      try {
        const response = await fetch("/data", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body)
        });

        if (response.ok || response.status === 401) {
          // Handle successful response (200-299 status codes)
          const data = await response.json();
          //methods.setValue("platforms", data.platforms, {shouldValidate: false });
          console.log("data retrieved");
          console.log(data);
          if (data && data.platforms) {
            methods.setValue("platforms", data.platforms);
           /* await Object.keys(data.platforms).forEach((key) => {
              methods.setValue(`platforms.${key}.checked`, true);

              if (data.platforms[key].cookie) {
                methods.setValue(`platforms.${key}.cookie`, data.platforms[key].cookie);
              }
              if (data.platforms[key].contextual) {
                methods.setValue(`platforms.${key}.contextual`,data.platforms[key].contextual);
                console.log(methods.getValues([`platforms.${key}.contextual`]));
              }
            });*/

          }
          if (response.status === 401) {
            setLoginStatus({ auth: false, email: localStorage.email, token: null });
          }
        }  else if (response.status === 404) {
          // Handle 404 Not Found error
          console.log("Resource not found.");
        } else if (response.status === 500) {
          // Handle 500 Internal Server Error
          console.log("Internal server error.");
        } else {
          // Handle other error statuses
          console.log("An error occurred:", response.status);
        }
      } catch (error) {
        // Handle network or other general errors
        console.log("An error occurred:", error);
      }

    }, []);

    useEffect(() => {
      values();
    }, [loginStatus.auth]);

  useEffect(() => {
    const shownInterstitial = localStorage.getItem("interstitial");
    console.log(shownInterstitial);
    if (shownInterstitial !== "true" ) {
      localStorage.setItem("interstitial", "true");
      setInterstitial(false);
      console.log(interstitial);
    }
    else {
      setInterstitial(true);
      console.log(interstitial);
    }
  }, []);

    const preventDefaultTab = (e) => {
      if (e.keyCode === 9) {
        e.preventDefault();
        return false;
      }
    };

    const disableTab = () => {
      window.addEventListener("keydown", preventDefaultTab, false);
    };

    useEffect(() => {
      disableTab();
    }, [disableTab]);


    const navigate = useNavigate();
    const [errorState, setErrorState] = useState(false);

    const saveForm = async (data) => {
      const form = new FormData();
      Object.keys(data).forEach((field, i) => {
        //form.append(field, data[field]);
        if (field === "platforms") {
          form.append(field, JSON.stringify(data[field]));
          //form.append(field, data[field]);
          //console.log(`FILTER: ${field} ${data[field]}`);
          //console.log(`FILTER: ${field} ${data[field]}`);
        } else if (field === "passwordOptional") {
          form.append("password", data[field]);
        } else if (field === "segments") {
          form.append(field, JSON.stringify(data[field]));
        } else {
          form.append(field, data[field]);
          //form.append(field, JSON.stringify(data[field]));
        }
        //console.log(`FILTER: ${field} ${JSON.stringify(data[field])}`);
      });

      if (loginStatus && loginStatus.auth) {
        form.append("account", JSON.stringify({ token: localStorage.token, email: localStorage.email }));
      }

      return await fetch("/submit", {
        method: "post",
        body: form
      });
    };


    const onSubmit = async (data, e) => {
      e.preventDefault();

      if (data.passwordOptional) {
        if (data.passwordOptional !== data.confirmOptional) {
          methods.setError("confirm", { message: "Passwords don't match" });
          setErrorState(true);
        }
      }

      let valid;
      if (data.email) {
        valid = await fetch("/validation", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: data.email })
        }).then((response) => response.json()
        ).catch((e) => {
          console.log(e);
          return false;
        });

        if (!valid || valid.userExists) {
          methods.setError("email", { message: "An account for this email already exists" });
          setErrorState(true);
        }
      }

      if (!data.email || (valid && !valid.userExists)) {

        await saveForm(data)
          .then((response) => {
            console.log("response");
            console.log(response);
            return response.status;
          })
          .then((status) => {
            if (status === 200) {
              navigate("/thanks");
            } else if (status === 409) {
              navigate("/duplicate");
            } else {
              navigate("/error");
              throw new Error("Failed to Submit Form");
            }
          })
          .catch((e) => {
            //TODO: Something went wrong page?
            console.log("SOMETHING WENT WRONG");
            console.log(e);
          });
      }
    };


    /*useEffect(() => {
      // you can do async server request and fill up form
      setTimeout(() => {
        methods.reset();
      }, 2000);
    }, [methods.reset])*/

    return (
      <Box sx={{ display: interstitial === false ? '' : "flex", flexDirection: interstitial === false ? '' : "row", m: 0 }}>
        <ToastContainer />
        {interstitial === false ? <CrosspixelNext setInterstitial={setInterstitial}/>  :
        <FormProvider {...methods} >
          <form
            id="siteForm"
            onSubmit={methods.handleSubmit(onSubmit)}>
            {isHero ? <Scroller loginStatus={loginStatus.auth}>
              <Hero0/>
              <Segment1/>
              <Reach2 />
              <Platform3 />
              <Info4 />
              <User5 guestOnly={true} />
            </Scroller>
            :
              <Scroller loginStatus={loginStatus.auth}>
              <Intro0 />
              <Audience1 />
              <Reach2 />
              <Platform3 />
              <Info4 />
              <User5 guestOnly={true} />
              </Scroller>
            }
          </form>
        </FormProvider> }
      </Box>
    );
  }
;

export default Form;
