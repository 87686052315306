import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
    maxWidth: "400px",
    width: "90%"
  },
  spacing: {
    /*
        paddingTop: theme.spacing(10)
    */
  }
}));

const CheckboxField = ({
                         name,
                         control,
                         rules,
                         addSpacing,
                         onClick,
                          label,
                         ...other
                       }) => {
  const classes = useStyles();


  //const setActive = useContext(ScrollerContext);
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field: { onChange, value, ref, ...field } }) => (
            <Checkbox
              onChange={() => {
                onChange(!value);
              }}
              inputRef={ref}
              checked={!!value}
            />
          )}
        />
      }
      label={<Typography variant="h6" color="textPrimary">{label}</Typography>}
    />
  );
};

export { CheckboxField };


