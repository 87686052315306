import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }
}));

const Submitted = () => {
  const classes = useStyles();

  return (
    <Grid sx={{ marginTop:"75px" }} className={classes.root}>
        <h1>Thank you!</h1>
        <p>
          <b>
            Please check your email for confirmation of your submission.
          </b>
        </p>
        <p>
          Contact us at <b>contact@crosspixel.net</b> with any questions.
        </p>
    </Grid>
  );
};

export default Submitted;
