import { makeStyles } from "@mui/styles";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import logo1 from "../static/NEXT_logo_cropped.png";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyItems: "center",
    justifyContent: "center",
    height: "100vh",
    margin: 0
  },
  buttonGroup: {
    margin: theme.spacing(1),
    right: "15vh",
    position: "fixed",
    bottom: "0vh"
  }
}));

const CrosspixelNext = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { setInterstitial} = props;


  const handleClick = () => {
    setInterstitial(true);
  }

  return (
    <Box direction="row" className={classes.root}>
      <Button  className={classes.buttonGroup}
                      variant="outlined" tabIndex={-1}
               onClick={handleClick}
      >
        Continue
      </Button>
    <Stack
        sx={{ height: "80vh", pl:20, pr:20, pb: 5, m: 0 }}
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Box
          component="img"
          className={'fade-in'}
          mb={5}
          sx={{
            height: 250,
            width: 772
          }}
          src={logo1}
        />
        <Typography variant={"h3"} mb={7} className={`${classes.Header} fade-in-6 montserrat-extra-light `}>
          Introducing Cross Pixel NEXT

        </Typography>

      <Typography variant={"h5"} mb={3} className={`${classes.Header} fade-in-9 montserrat-extra-light `}>
        A contextual solution
        powered by audience intent.

      </Typography>

      <Typography variant={"h5"} className={`${classes.Header} fade-in-10 montserrat-extra-light `}>
        NEXT standard segments and custom audiences are now
        fully integrated and accessible
        in the contextual area of The Trade Desk.
      </Typography>



      </Stack>

    </Box>
  );
};

export default CrosspixelNext;
