import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"black",
    position:"relative",
    bottom: "0",
    width: "100%",
    padding:"20px",
    zIndex: -99
  }
}));


const Footer = () => {

  const classes = useStyles();

  return (
    <Box className={classes.root}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
      component="footer"
    >
      <Container maxWidth="sm">
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="hero.crsspxl.com">
            Hero.crsspxl.com
          </Link>
          {" 2023"}.
        </Typography>
      </Container>
    </Box>
  );
}

export {Footer}
