import { CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Notification } from "../components/Notification";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }
}));

const Confirm = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const classes = useStyles();

  let { id } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    const loaded = () => {
      setLoading(!loading);
    };

    const succeeded = () => {
      setSuccess(!success);
    };

    const confirm = async () => {
      try {
        //TODO: SANITIZE
        const response = await fetch("/confirm", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: id })
        });
        const status = response.status;
        if (status === 200) {
          succeeded();
        }

      } catch (e) {
        console.log(e.stack);
      }
    };

    const confirmed = confirm();
    if (confirmed) {
      loaded();
      setRedirecting(true);
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  }, []);

  return (
    <div className={classes.root}>

      {!loading && success && (
        <Grid sx={{ marginTop:"75px" }} className={classes.root}>
          <h1>Thank you for verifying your email!</h1>
          <CheckCircle />
          <p>
            Contact us at <b>contact@crosspixel.net</b> with any questions.
          </p>
        </Grid>
      )}
      {redirecting && (
        <Notification message={"Redirecting to main page in 5 seconds ..."}/>
      )}
      {!loading && !success && (
        <Grid sx={{ marginTop:"75px" }} className={classes.root}>
          <h1>Confirmation Failed!</h1>
          <p>
            It looks like something went wrong. We have sent you another
            confirmation link. (Links expire in 3 days)
          </p>
          <p>
            Contact us at <b>contact@crosspixel.net</b> with any questions.
          </p>
        </Grid>
      )}

      {loading && <CircularProgress size="10vh" color="secondary" />}
    </div>
  );
};

export default Confirm;
