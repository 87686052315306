import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { useForm, Controller } from "react-hook-form";
import { getToken } from "../components/Recaptcha";
import { BasicFormField } from "../components/BasicFormField";
import { company, confirm, email, fullname, password, phone } from "../store/formContent";
import { Alert, AlertTitle, Fade, IconButton, InputAdornment } from "@mui/material";
import { PasswordOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const Register = ({updateLogin}) => {
  const classes = useStyles();
  const { handleSubmit, control, getValues } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value) => {
    const passValues = getValues(['password','confirm']);
    if (passValues[0] !== passValues[1]) {
      return  "Passwords don't match";
    }
  }

  const saveForm = async (data) => {
    return await fetch("/register", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...data })
    });
  };

  const onSubmit = async (data, e) => {
    //unmountComponentAtNode(document.getElementById("root"));
    e.preventDefault();
    e.stopPropagation();
    setError(false);
    await saveForm(data)
      .then((response) => {
        return response.status;
      })
      .then((status) => {
        if (status === 200) {
          //finishSubmitting();

          /* } else if (status === 409) {
             redirecting("duplicate");*/
        } else {
          //TODO: error status
          setError(true);
          //navigate("/error");
          //redirecting("error");
          //throw new Error("Failed to Submit Form");
        }
      })
      .catch((e) => {
        //TODO: Something went wrong page?
        console.log("SOMETHING WENT WRONG");
        console.log(e);
        setError(true);
      });
    if (!error) {
      try {
       const response =  await fetch("/login", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...data })
        });

        const json = await response.json();
        console.log(json);
        if (json && json.auth && json.token && json.email) {
          localStorage.setItem("token", json.token);
          localStorage.setItem("email", json.email);
          updateLogin({ auth: true, email: json.email, token: json.token });
          setError(false);
        } else {
          setError(true);
        }
      }
      catch (e) {
        console.log("SOMETHING WENT WRONG");
        console.log(e);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <BasicFormField {...email} control={control} />
      <BasicFormField {...password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      type={showPassword ? "text" : "password"}
                      control={control} />
      <BasicFormField {...confirm}
                      rules={{validate: validatePassword}}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      type={showPassword ? "text" : "password"}
                      control={control} />
      <BasicFormField {...fullname} control={control} />
      <BasicFormField {...company} control={control} />
      {/*<BasicFormField {...phone} control={control} />*/}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Register
      </Button>
      {error && <Alert severity="error">{"Already registered"}</Alert>}
    </form>
  );
};


export default Register;