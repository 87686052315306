import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { AccountBoxOutlined } from "@mui/icons-material";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Box, Chip, Dialog, Paper, Popover, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { TabPanel } from "../components/TabPanel";
import { FileUploader } from "react-drag-drop-files";
import Login from "./Login";
import { makeStyles } from "@mui/styles";
import Register from "./Register";
import { log } from "util";


const useStyles = makeStyles((theme) => ({
  popup: {
    width: "800px"
  },
  paper: {
    display:"flex",
    flexDirection: "row",
    alignItems: "center"
  },
  tabs: {
   /* display:"flex",
    flexDirection: "column",*/
    minWidth: "365px",
    width: "40vw",
    minHeight: "300px",
    padding: theme.spacing(1),
    textAlign: "center"
  },
}));

const UserDialog = ({ loginStatus, updateLogin}) => {

  const classes = useStyles();

  const handleLogout = () => {
    updateLogin({ auth: false, email: null, token: null });
    localStorage.removeItem("token");
    localStorage.removeItem("email");
  };

  const [selectedTab, setSelectedTab] = useState("login");
  const buttonRef = useRef(null);

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    if (loginStatus.auth === false && loginStatus.email) {
      buttonRef.current.click();
      console.log("POPOUT MODAL");
    }

  }, [loginStatus.auth, loginStatus.email]);


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{display: "flex",flexDirection: "row", right:"1vw" }}>
      <Button ref={buttonRef} onClick={handleClick}>
        <AccountBoxIcon color="action" fontSize={"large"} />
      </Button>

      <Popover
        className={classes.popup}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >


        <Box className={classes.paper}>
          {loginStatus.auth && loginStatus.email &&
          <Stack direction={"row"} sx={{alignItems:"center", textAlign:"center"}}>
            <Typography m={2} component={"div"} variant={"body1"}> <Chip m={1} label={loginStatus.email} color="primary" /> </Typography>
            <Typography m={2} component={"div"} variant={"body1"}> <Button variant={"text"} onClick={handleLogout}>Logout</Button> </Typography>
          </Stack>
          }
          {!loginStatus.auth &&
          <Box className={classes.tabs}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab value={"login"} label="Login" />
              <Tab value={"register"} label="Register" />
            </Tabs>
            <TabPanel tabId={selectedTab} value={"login"}>
              <Login updateLogin={updateLogin} />
            </TabPanel>
            <TabPanel tabId={selectedTab} value={"register"}>
              <Register updateLogin={updateLogin} />
            </TabPanel>
          </Box>}
        </Box>

      </Popover>

    </Box>


    );



};
export { UserDialog };