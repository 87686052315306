import { Avatar, Box, Button, ButtonGroup, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AccountBoxOutlined, AccountBoxTwoTone, ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
//import { disableScroll } from "../utils/disableScroll";
import { cloneElement, createContext, useCallback, useEffect, useRef, useState } from "react";
import { Recaptcha } from "./Recaptcha";
import { Slide } from "./Slide";
import Login from "../pages/Login";
import { UserDialog } from "../pages/UserDialog";
import { useFormContext } from "react-hook-form";
//import throttle from "lodash.throttle";
//import { isScrollingDown } from "../utils/scrolling";
//import useOnScreen from "../utils/useOnScreen";

/*
const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    /!*
    display: "block",
    *!/
    margin: theme.spacing(1),

    right: "15vh",
    position: "fixed",
    bottom: "0vh"
  },
  root: {},
  /!*div: {
    overflow: "auto",
    display: "block",
  },*!/
  child: {
    scrollSnapAlign: "start",
    scrollSnapStop: "always"
  }
  /!*boxShadow: {
    boxShadow: `10px 5px 5px ${theme.palette.primary.main}`,
  },*!/
}));
*/

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    /*alignItems: "center",
    alignContent: "center",*/
    justifyItems: "center",
    justifyContent: "center",
    height: "100vh",
    margin: 0

  },
  BoxSlide: {

    //alignItems: "center",
    //alignContent: "center",
    //justifyItems: "center",
    justifyContent: "space-evenly"
  },
  child: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",

    scrollSnapAlign: "start",
    scrollSnapStop: "always",
    scrollSnapType: "x mandatory"

  },
  buttonGroup: {
    margin: theme.spacing(1),
    right: "15vh",
    position: "fixed",
    bottom: "0vh"
  }
}));

const Scroller = ({ loginStatus, children }) => {
  //const theme = useTheme();

  const classes = useStyles();
  //const nChildren = children.length;

  //// NEW
  const { trigger, setFocus, formState: { errors } } = useFormContext();

  const [activeField, setActiveField] = useState(0); //field by number
  const activeFieldRef = useRef(null); // field by element
  const containerRef = useRef(null);
  const slideCount = useRef(0);
  const [direction, setDirection] = useState(true);
  const [validationList, setValidationList] = useState([]);
  const activeValidRef = useRef(false);
  children = children.filter(Boolean);


  useEffect(() => {
    if (loginStatus) {
      slideCount.current = children.length - 1; // -1 b/c currently just one "guestOnly" slide

    } else {
      slideCount.current = children.length;
    }

  }, [loginStatus]);


  /* useEffect(() => {
     console.log("validationList UP");
     console.log(validationList);
   }, [validationList]);*/


  useEffect(() => {
    const focusActiveFieldRef = () => {
      if (activeFieldRef.current) {
        activeFieldRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest"
        });

        activeFieldRef.current.focus();
      }
    };
    focusActiveFieldRef();

  }, [activeField]);



  const movingNext = useCallback(async () => {
      if (validationList && validationList.length > 0) {
        const result = await trigger(validationList);
        if (result === true) {
          setActiveField(Math.min(slideCount.current - 1, activeField + 1));
        } else {
          if (errors) {
            // Because of the way errors are nested Platform errors need to be handled differently
            const errorList = (errors.platforms && errors.hasPlatform )? ['platforms','hasPlatform'] : Object.keys(errors);
            if (errorList[0]) {
              if (errorList[0] === 'platforms') { // platform errors
                const firstPlatformError = Object.keys(errors?.platforms)[0];
                if (firstPlatformError === "Other") {
                  setFocus('platforms.Other.request');
                }
                else {
                  if ( errors.platforms[firstPlatformError].cookie) {
                    setFocus(`platforms[${firstPlatformError}].cookie`);
                  }
                  else if (errors.platforms[firstPlatformError].contextual) {
                    setFocus(`platforms[${firstPlatformError}].contextual`);
                  } else if (errors.platforms[firstPlatformError].next) {
                    setFocus(`platforms[${firstPlatformError}].next`);
                  }
                }
              } else { // All other errors
                setFocus(errorList[0]);
              }
            }
          } else {
            console.log("Something went wrong...");
          }
        }
      } else {
        setActiveField(Math.min(slideCount.current - 1, activeField + 1));
      }
    },
    [validationList, activeField, slideCount]);


  const movingPrevious = useCallback(() => {
    setActiveField(Math.max(0, activeField - 1));
  }, [activeField]);

  return (
    <Box direction="row" className={classes.root} ref={containerRef}>
      <ButtonGroup
        className={classes.buttonGroup}
        orientation="horizontal"
        color="primary"
        variant="outlined" aria-label="outlined button group"
      >
        <Button variant="outlined" tabIndex={-1} disabled={activeField === 0}
                onClick={movingPrevious}>
          Previous
        </Button>
        <Button variant="outlined" tabIndex={-1}
                disabled={slideCount.current - 1 === activeField} onClick={movingNext}>
          Next
        </Button>
      </ButtonGroup>


      {children.map((child, i) => {
        const active = i === activeField;

        const newChild = cloneElement(child, {
          slideId: i,
          next: movingNext,
          previous: movingPrevious,
          setActive: setActiveField,
          setValidationList: setValidationList,
          activeField: activeField,
          loginStatus: loginStatus
        });

        if (loginStatus && newChild.props.guestOnly) {
          //console.log("LOGGED IN"); // skip creating contact/login info slide(s)
        } /*else if (newChild.props.interstitialOnce && interstitial === true) {
          // skip creating interstitial page
          console.log("HERE1")
        }*/
        else {
        /*  if (newChild.props.interstitialOnce && localStorage.getItem("interstitial") !== "true") {
            console.log("HERE")
            localStorage.setItem("interstitial", "true");
          }*/
          return (
            <Box
              className={`${classes.child} field ${active ? "active" : ""}`}
              ref={active ? activeFieldRef : null}
              id={`field-${i}`}
              key={`field-${i}`}
            >
              {newChild}
            </Box>
          );
        }
      })}
    </Box>
  );
};

export { Scroller };
