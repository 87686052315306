import { InputAdornment, Button, Typography, Grid, Alert, AppBar, Toolbar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormProvider, useForm } from "react-hook-form";
import { Scroller } from "../components/Scroller";
import { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Segment1 } from "./slides/Segment1";
import { Reach2 } from "./slides/Reach2";
import { Platform3 } from "./slides/Platform3";
import { Info4 } from "./slides/Info4";
import { User5 } from "./slides/User5";
import { Intro0 } from "./slides/Intro0";
import { Hero0 } from "./slides/Hero0";

const Form = ({ loginStatus, setLoginStatus }) => {

    let methods = useForm();

    const values = useCallback(async () => {

      const body = {
        account: { token: localStorage.token, email: localStorage.email }
      };



      try {
        const response = await fetch("/data", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body)
        });

        if (response.ok || response.status === 401) {
          // Handle successful response (200-299 status codes)
          const data = await response.json();
          methods.setValue("platforms", data.platforms);

          if (response.status === 401) {
            setLoginStatus({ auth: false, email: localStorage.email, token: null });
          }
        }  else if (response.status === 404) {
          // Handle 404 Not Found error
          console.log("Resource not found.");
        } else if (response.status === 500) {
          // Handle 500 Internal Server Error
          console.log("Internal server error.");
        } else {
          // Handle other error statuses
          console.log("An error occurred:", response.status);
        }
      } catch (error) {
        // Handle network or other general errors
        console.log("An error occurred:", error);
      }

      /*  const result = await fetch("/data", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body)
        }).then((response) => response.json())
          .then((data) => {
            if (!data.platforms) {
              methods.setValue("platforms", {
                DV360: {},
                Yahoo: {},
                MediaMath: {},
                OpenX: {},
                TradeDesk: {},
                Xandr: {},
                Other: {}
              });
            } else {
              methods.setValue("platforms", data.platforms);
            }
          });*/
    }, []);

    useEffect(() => {
      values();
    }, [loginStatus.auth]);

    const preventDefaultTab = (e) => {
      if (e.keyCode === 9) {
        e.preventDefault();
        return false;
      }
    };

    const disableTab = () => {
      window.addEventListener("keydown", preventDefaultTab, false);
    };

    useEffect(() => {
      disableTab();
    }, [disableTab]);


    const navigate = useNavigate();
    const [errorState, setErrorState] = useState(false);

    const saveForm = async (data) => {
      const form = new FormData();
      Object.keys(data).forEach((field, i) => {
        //form.append(field, data[field]);
        if (field === "platforms") {
          form.append(field, JSON.stringify(data[field]));
          //form.append(field, data[field]);
          //console.log(`FILTER: ${field} ${data[field]}`);
          //console.log(`FILTER: ${field} ${data[field]}`);
        } else if (field === "passwordOptional") {
          form.append("password", data[field]);
        } else if (field === "segments") {
          form.append(field, JSON.stringify(data[field]));
        } else {
          form.append(field, data[field]);
          //form.append(field, JSON.stringify(data[field]));
        }
        //console.log(`FILTER: ${field} ${JSON.stringify(data[field])}`);
      });

      if (loginStatus && loginStatus.auth) {
        form.append("account", JSON.stringify({ token: localStorage.token, email: localStorage.email }));
      }

     return await fetch("/submit", {
        method: "post",
        body: form
      });
    };


    const onSubmit = async (data, e) => {
      e.preventDefault();

      if (data.passwordOptional) {
        if (data.passwordOptional !== data.confirmOptional) {
          methods.setError("confirm", { message: "Passwords don't match" });
          setErrorState(true);
        }
      }

      let valid;
      if (data.email) {
        valid = await fetch("/validation", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: data.email })
        }).then((response) => response.json()
        ).catch((e) => {
          console.log(e);
          return false;
        });

        if (!valid || valid.userExists) {
          methods.setError("email", { message: "An account for this email already exists" });
          setErrorState(true);
        }
      }

      if (!data.email || (valid && !valid.userExists)) {

        await saveForm(data)
          .then((response) => {
            return response.status;
          })
          .then((status) => {
            if (status === 200) {
              navigate("/thanks");
            } else if (status === 409) {
              navigate("/duplicate");
            } else {
              navigate("/error");
              throw new Error("Failed to Submit Form");
            }
          })
          .catch((e) => {
            //TODO: Something went wrong page?
            console.log("SOMETHING WENT WRONG");
            console.log(e);
          });
      }
    };


    /*useEffect(() => {
      // you can do async server request and fill up form
      setTimeout(() => {
        methods.reset();
      }, 2000);
    }, [methods.reset])*/

    return (
      <Box sx={{ display: "flex", flexDirection: "row", m: 0 }}>
        <FormProvider {...methods} >
          <form
            id="siteForm"
            onSubmit={methods.handleSubmit(onSubmit)}>
            <Scroller loginStatus={loginStatus.auth}>
              <Hero0 />
              <Segment1 />
              <Reach2 />
              <Platform3 />
              <Info4 />
              <User5 guestOnly={true} />
            </Scroller>
          </form>
        </FormProvider>
      </Box>
    );
  }
;

export default Form;
