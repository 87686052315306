import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
    width: "30vw"
  }
}));



const BasicFormField = (
  props
) => {
 /* onChange: () => {
    if (errors.email) {
      clearErrors('email')
    }*/
  const classes = useStyles();
  //const { clearErrors} = useFormContext();

  //const { ...other } = props;
  const {
    id,
    name,
    control,
    defaultValue,
    label,
    rules,
    inputProps,
    InputProps,
    type, unregister,
    ...other
  } = props;

  return (
    <Controller
      id={id}
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={unregister}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextField
          sx={{ maxWidth: "500px" }}
          className={classes.input}
          label={label}
          variant="outlined"
          value={value}
          onChange={onChange}
          onFocus={(e) => {
            //clearErrors();
          }}
          error={!!error}
          helperText={error ? error.message : null}
          inputProps={inputProps}
          InputProps={InputProps}
          inputRef={ref}
          type={type}
          {...other}
        />
      )}
      rules={rules}
    />
  );
};
export { BasicFormField };
