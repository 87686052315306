import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormHelperText } from "@mui/material";


const key = process.env.REACT_APP_SITE_KEY;


const Recaptcha = ({ captchaResponse, form }) => {

    const { register, setError, clearErrors, setValue, getValues, formState: { errors }, unregister } = useFormContext();


    useEffect(() => {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback`;
      //script.defer = true;
      //script.addEventListener("load", handleLoaded);
      document.body.appendChild(script);

      window.onloadCallback = (res) => {
        console.log("reCAPTCHA has loaded!");
      };

    }, []);

    useEffect(() => {


      window.captchaCallback = (res) => {
        clearErrors('Recaptcha');

        captchaResponse(res);
        setValue("Recaptcha", res);
      };

      window.captchaReset = (res) => {
        captchaResponse.set("");
        setValue("Recaptcha", "");
      };
    }, []);


    return (
      <div>
        <div {...register("Recaptcha", {required: "Captcha is required"}
              )
             } className="g-recaptcha" data-sitekey={key} data-callback="captchaCallback"
             data-expired-callback={"captchaReset"} />

        {errors.Recaptcha && (
          <FormHelperText id="component-error-text" error>{errors.Recaptcha.message }</FormHelperText>
        )}
      </div>
    );
  }
;

export { Recaptcha };
