import { Box, Stack, Typography } from "@mui/material";
import logo from "../../static/logo.png";
import logo2 from "../../static/Hero-logo.png";
import { Image } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({

  Header: {
    paddingTop: theme.spacing(5),
    maxWidth: "1000px",
    width: "70%",
    color: theme.palette.text.primary
  },
  Subtitle: {
    paddingTop: theme.spacing(5),
    maxWidth: "1000px",
    width: "50%",
    color: theme.palette.text.secondary
  },
  heroHeader: {
    paddingTop: theme.spacing(5),
    maxWidth: "1000px",
    width: "100%",
    color: theme.palette.text.primary,
    fontFamily: [
      "\"Open Sans\"",
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\""
    ].join(","),
    fontWeight: 800,
    textTransform: "uppercase"
  },
  heroSubheader: {
    paddingTop: theme.spacing(5),
    maxWidth: "1000px",
    width: "100%",
    color: "#8d8d8d",
    fontFamily: [
      "\"Open Sans\"",
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\""
    ].join(","),
    fontWeight: 300,
    fontSize: "1.5rem"
  }
}));


const Hero0 = (props) => {

  const classes = useStyles();
  const { slideId, activeField, setActive, lastPrompt, setValidationList, children } = props;


  useEffect(() => {
    if (activeField === slideId) {
      setValidationList([]);
    }
  }, [activeField]);

  return (
    <Stack
         sx={{ height: "80vh", pb:5, m:0 }}
         direction="column"
         justifyContent="center"
         alignItems="center"
         textAlign="center"
        >
      <Box
        component="img"
        sx={{
          height: 321,
          marginTop: 10
        }}
        src={logo2}
      />
      <Typography variant={"h3"} className={classes.heroHeader}>
        Introducing Hero Data
      </Typography>

      {/*<Typography variant={"h4"} className={`${classes.heroSubheader} make-uppercase`}>*/}
      <Typography variant={"h4"} className={`${classes.heroSubheader}`}>
        Empowering Programmatic Advertising with Multicultural
        Targeting
      </Typography>


    </Stack>

  );
};

export
{
  Hero0
};