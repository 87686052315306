import { Box, Typography } from "@mui/material";
import { useEffect } from "react";



const TabPanel = (props) => {
  const {
    children,
    value: id,
    tabId,
    ...other
  } = props


 /* useEffect(() => {
  console.log(id);
  console.log(tabId);
  }, [id, tabId]);*/


  return (
    <Box
        sx={{display: (id === tabId) ? "flex" : "none" /* visibility: (id === tabId) ? "visible" : "hidden" */}}
      {...other}
    >
        <Typography sx={{ p: 3, width:"100%"}} component={"div"}>{children}</Typography>
    </Box>
  )



  /*const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ display: "flex", }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width:"100%"}}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </Box>
  );*/
}
export {TabPanel};