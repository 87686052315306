import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { BasicFormField } from "../components/BasicFormField";
import { company, confirm, email, fullname, password } from "../store/formContent";
import { Alert, Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    direction: "horizontal"

  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const ForgotPasswordConfirmation = () => {
  const classes = useStyles();

  return (
    <Grid sx={{ marginTop: "75px" }} className={classes.root}>
      <h1>Thank you!</h1>
      <p>
        <b>
          If you have a valid account, you will receive an email with a reset password link.
        </b>
      </p>
      <p>
        Contact us at <b>contact@crosspixel.net</b> with any questions.
      </p>
    </Grid>
  );
};

const ResetPasswordForm = () => {
  const classes = useStyles();
  const { handleSubmit, control, getValues } = useForm();
  let { id } = useParams();
  const navigate = useNavigate();


  const [showPassword, setShowPassword] = useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value) => {
    const passValues = getValues(['password','confirm']);
    if (passValues[0] !== passValues[1]) {
      return  "Passwords don't match";
    }
  }

  const saveForm = async (data) => {


    return await fetch("/forgot-password/" + id, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...data })
    });
  };

  const onSubmit = async (data, e) => {
    //unmountComponentAtNode(document.getElementById("root"));
    e.preventDefault();
    e.stopPropagation();
    await saveForm(data)
      .then((response) => {
        return response.status;
      })
      .then((status) => {
        if (status === 200) {
          navigate("/forgot-password/success")
        }
        else if (status === 401) {
          // token expired
          navigate("/forgot-password/expired")
        }
        else {
          navigate("/error");
        }
      })
      .catch((e) => {
        //TODO: Something went wrong page?
        console.log("SOMETHING WENT WRONG");
        console.log(e);
        navigate("/error");
      });

  };


  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
    <Stack sx={{ marginTop:"75px" }} className={classes.root} spacing={3}>

       {/* <BasicFormField {...email} control={control} />*/}
        <BasicFormField {...password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        type={showPassword ? "text" : "password"}
                        control={control} />
        <BasicFormField {...confirm}
                        rules={{validate: validatePassword}}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        type={showPassword ? "text" : "password"}
                        control={control} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Change Password
        </Button>

    </Stack>
    </form>
  );
};

const ResetPasswordExpired = () => {
  const classes = useStyles();

  return (
    <Grid  sx={{ marginTop:"75px" }}  className={classes.root}>
      <h1>Oops!</h1>
      <p>
        <b>Your reset password link has expired, please click the 'Forgot Password' link and try again.</b>
      </p>
      <p>
        Contact us at <b>contact@crosspixel.net</b> with any questions.
      </p>
    </Grid>
  );
};

const ResetPasswordSuccess = () => {
  const classes = useStyles();

  return (
    <Grid  sx={{ marginTop:"75px" }}  className={classes.root}>
      <h1>Success!</h1>
      <p>
        <b>You've changed your password, to continue please Login</b>
      </p>
      <p>
        Contact us at <b>contact@crosspixel.net</b> with any questions.
      </p>
    </Grid>
  );
};

export { ForgotPasswordConfirmation, ResetPasswordForm, ResetPasswordExpired, ResetPasswordSuccess } ;
