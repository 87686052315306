import React, { useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { BasicFormField } from "../components/BasicFormField";
import { email, password } from "../store/formContent";
import { Alert, Dialog, IconButton, InputAdornment, Stack } from "@mui/material";
import { AccountBoxOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textAlign:"center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },

  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const Login = ({ updateLogin }) => {
    const classes = useStyles();
    const { register, handleSubmit, control, getValues, setError, clearErrors } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [comboError, setComboError] = useState(false);
    const [noEmailError, setNoEmailError] = useState(false);
    const navigate = useNavigate();


    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const submitForgotPassword = async (userEmail) => {
      return await fetch("/forgot-password", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "email": userEmail })
      });
    };

    const handleForgotPassword = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const values = getValues();
      if (values && values.email) {
        const isValid = await email.rules.validate(values.email);
        if (isValid === true) {
          clearErrors("email");
          await submitForgotPassword(values.email)
            .then((response) => {
              return response.status;
            })
            .then((status) => {
              if (status === 200) {
                navigate("/forgot-password");
              } else {
                navigate("/error");
              }
            })
            .catch((e) => {
              //TODO: Something went wrong page?
              console.log("SOMETHING WENT WRONG");
              console.log(e);
              navigate("/error");

            });
        } else {
          setError("email", { type: "custom", message: isValid });
        }
      } else {
        setError("email", { type: "custom", message: "Please enter your email" });
      }
    };

    const saveForm = async (data) => {
      return await fetch("/login", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...data })
      });
    };

    const onSubmit = async (data, e) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        console.log(data);
        const response = await saveForm(data);
        const json = await response.json();
        console.log(json);
        if (json && json.auth && json.token && json.email) {
          localStorage.setItem("token", json.token);
          localStorage.setItem("email", json.email);
          updateLogin({ auth: true, email: json.email, token: json.token });
          setComboError(false);
          const shownBanner = localStorage.getItem("banner");
          if (shownBanner !== "true" ) {
            localStorage.setItem("banner", "true");
            setTimeout(() => {
              navigate("/crosspixel-next");
            }, 1000);
          }

        } else {
          setComboError(true);
        }
      } catch (e) {
        //TODO: Something went wrong page?
        console.log("SOMETHING WENT WRONG");
        console.log(e);
      }
    };


    return (
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack className={classes.paper}>
          <BasicFormField {...email} control={control} />
          <BasicFormField {...password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          type={showPassword ? "text" : "password"}
                          control={control} />

          <Button
            type="submit"
            fullWidth
            sx={{ maxWidth: "500px" }}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Link component="button"

                onClick={handleForgotPassword}>
            Forgot Password? Enter your account email in the field above then click here
          </Link>
          {comboError && <Alert severity="error">{"Wrong email/password"}</Alert>}
          {noEmailError && <Alert severity="error">{"Please enter your email"}</Alert>}
        </Stack>

        {/*<Grid container>*/}
        {/*  <Grid item xs>*/}
        {/*    <Link href="#/forgot-password" variant="body2">*/}
        {/*      Forgot password?*/}
        {/*    </Link>*/}
        {/*  </Grid>*/}
        {/*  <Grid item>*/}
        {/*    <Link href="#/register" variant="body2">*/}
        {/*      {"Don't have an account? Sign Up"}*/}
        {/*    </Link>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </form>

    );
  }
;


export default Login;