import { Alert, AlertTitle, Fade } from "@mui/material";
import React, { useState } from "react";

const Notification = ({message}) => {

  const [visibility, setVisibility] = useState(false);

  return (
    <Fade
      in={visibility} //Write the needed condition here to make it appear
      timeout={{
        enter: 0,
        exit: 4000
      }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
      addEndListener={() => {
        setTimeout(() => {
          setVisibility(true);
        }, 2000);
      }}
    >
      <Alert severity="success" variant="standard" className="alert">
        <AlertTitle>Success</AlertTitle>
        {message}
      </Alert>
    </Fade>
  );
};

export {Notification};