import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../static/logo.png";
//import heroLogoSmall from "../static/Hero-logo_small.png";
//import hLogoSmall from "../static/H_logo_small.png";
//import { createTheme, ThemeProvider } from "@mui/material";
//import { red } from '@mui/material/colors';
import { Link } from "react-router-dom";

const isHero = process.env.REACT_APP_HERO === "true";

/*
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: red
  }
});
*/

export default function ButtonAppBar(props) {
  const {title, children} = props;
  return (
    <Box sx={{ flexGrow: 1 }}>
{/*
      <ThemeProvider theme={theme}>
*/}
      <AppBar position="fixed" >
        <Toolbar disableGutters>
          <Link to="/">
            <Box sx={{ display: "flex", height: "40px" }}
                 paddingLeft={3}
                 paddingRight={1}
                 component="img"
               src={logo} />

          </Link>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              flexGrow: 1,
            }}
          >
            {title}
          </Typography>
          {isHero && <Button
            variant="h6"

            component="a"
            href="/#/about"
            sx={{
              mr: 2,
              fontFamily: 'Open Sans',
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            About Us
          </Button>}
          {/*</Box>*/}
          {children}
        </Toolbar>
      </AppBar>
     {/* </ThemeProvider>*/}
    </Box>
  );
}