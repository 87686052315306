import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { UserDialog } from "./pages/UserDialog";
import { red, orange } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: "dark"
  }
});


const heroTheme = createTheme({
  palette: {
    mode: "dark",
    primary: red
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans"
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
      .make-uppercase {
          text-transform: uppercase
        },
      .header-font {
        font-family: "Open Sans",
        text-transform: uppercase
      }  
        
      `
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

