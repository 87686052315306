import * as React from "react";
/*
import {
  DataGrid,
  GridFilterPanel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import { Alert, Box, Grid, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Prompt } from "./Prompt";
import { useForm, Controller } from "react-hook-form";


const useStyles = makeStyles((theme) => ({
  /!* root: {
     justifyContent: "center",
     alignItems: "center"
   },
   typography: {
     paddingTop: theme.spacing(10),
     paddingBottom: theme.spacing(15),
   },*!/
  div: {
    /!*height: "50vh",
    width: "50vh",*!/
  }
  /!*div2: {},*!/
}));

const columns = [
  { field: "name", headerName: "Audience", flex: 1 }
];

function TableView({ setSelected, rows, index, addSpacing, prompt, id,name, control, rules }) {
  const theme = useTheme();

  const classes = useStyles(theme);
  const [selectionModel, setSelectionModel] = useState([]);


  return (
    <Grid item xs={12}>
      <Prompt prompt={prompt} index={index} />
      {addSpacing && <Grid className={classes.spacing} />}
    </Grid>
  );
}
*/

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FeedIcon from '@mui/icons-material/Feed';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Prompt } from "./Prompt";
import { Divider, Grid, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Search, Visibility, VisibilityOff } from "@mui/icons-material";
import { BasicFormField } from "./BasicFormField";
import { useForm } from "react-hook-form";
import { SearchField } from "./SearchField";
import ClickAwayListener from '@mui/material/ClickAwayListener';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Audience"
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all audiences"
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
  const { setSelected, numSelected, children } = props;

  const clearSelection = () => {
    setSelected({});
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {children}

      {numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%", pl: 2 }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
          <Tooltip title="Delete">
            <IconButton
              aria-label="clear selected"
              onClick={clearSelection}
              onMouseDown={clearSelection}
              >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Typography>

      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const TableView = ({ setSelectionModel, allRows, index, prompt, id, name, control, rules }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [rows, setRows] = useState([]);
  /*const [open, setOpen] = useState(false); // For description Tooltip

  // For handling click functionality of description Tooltip
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };*/


/* const updateSearch = (event) => {
   const query = event.target.value;

   return

  };*/

/*  useEffect(() => {
    setRows(allRows);
  }, [ allRows]);*/

  /* useEffect(() => {
     if (search) {
       const test = allRows.filter((row) => row.name.toLowerCase().includes(search.toLowerCase()))
       console.log(test);
     }
   }, [ search, setRows, allRows]);*/

  const updateSelection = (selected) => {
    setSelected(selected)
    setSelectionModel(selected)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    let newSelected = {};
    if (event.target.checked) {
     rows.forEach((n) => {
       newSelected[n.name] = n.id;
      });
      updateSelection(newSelected);
    }
    else {
      updateSelection({});
    }

  };

  const handleClick = (event, row) => {
    //const selectedIndex = selected.indexOf(name);
    let newSelected = {...selected};

    if (newSelected[row.name]) {
      delete newSelected[row.name];
    }
    else {
      newSelected[row.name] = row.id;
    }
    updateSelection(newSelected)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => !!selected[name];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Grid sx={{ marginTop: 0 }} item xs={12}>
      <Prompt prompt={prompt} index={index} />
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar setSelected={updateSelection} numSelected={Object.keys(selected).length || 0}>
            <SearchField allRows={allRows} setRows={setRows} />
            {/*<TextField sx={{ flex: "1 1 100%" }} id="outlined-search" label="Search field" type="search" value={search}
                       onSubmit={updateSearch}*/}
          </EnhancedTableToolbar>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              stickyHeader aria-label="sticky table"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead
                numSelected={Object.keys(selected).length || 0}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${row.id}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell color="primary">

                          <Tooltip
                            title={row.description}
                            placement="right"
                            arrow>
                            <IconButton>
                              <HelpCenterIcon />
                            </IconButton>
                          </Tooltip>

                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (33) * emptyRows
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{ display: { xl: "none", xs: "none" } }}
            rowsPerPageOptions={[50, 100, 200]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Grid>
  );
};


export { TableView };
