import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import Form from "../pages/Form";
import ThemeToggle from "../components/ThemeToggle";
import Submitted from "../pages/Submitted";
import CrosspixelNext from "../pages/CrosspixelNext";
import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Confirm from "../pages/Confirm.js";
import Error from "../pages/Error.js";
import NotFound from "../pages/NotFound.js";
import Login from "../pages/Login";
import Register from "../pages/Register";
import { AppBar, Box, Button, createTheme, Grid, Stack, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { UserDialog } from "../pages/UserDialog";
import { makeStyles } from "@mui/styles";
import logo from "../static/logo.png";
import { AccountBoxOutlined } from "@mui/icons-material";
import ButtonAppBar from "../components/Navbar";
import HeroForm from "../pages/HeroForm";
import AboutUs, { AboutUsHero } from "../pages/AboutUs";
import { Footer } from "../components/Footer";
import ForgotPassword, {
  ForgotPasswordConfirmation, ResetPasswordExpired,
  ResetPasswordForm,
  ResetPasswordSuccess
} from "../pages/ForgotPassword";
const isHero = process.env.REACT_APP_HERO === "true";

function App() {

  const [submitted, setSubmitted] = useState(false);
  const [start, setStart] = useState(false);
  const [loginStatus, setLoginStatus] = useState({ auth: false, email: null, token: null });
  const navigate = useNavigate();

  // const [focus, setFocus] = useState(false);

  useEffect(() => {
    return () => {
      window.onbeforeunload = function() {
        window.scrollTo(0, 0);
      };
    };
  });

  useEffect(() => {
    //Fetch auth here
    if (localStorage.token && localStorage.email) {
      setLoginStatus({ auth: true, email: localStorage.email, token: localStorage.token });
    }

  }, []);

  const updateLoginStatus = (status) => {
    setLoginStatus(status);
  };

  const finishSubmitting = () => {
    setSubmitted(!submitted);
  };

  const clickedStart = () => {
    setStart(!start);
  };


  /*
  {
        loginStatus.auth &&
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {loginStatus.email}
            </Typography>
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          </Toolbar>
        </AppBar>
      }
  * */


  return (
    <Box>
      <ButtonAppBar title={isHero ? "" : "TREBLE"}> <UserDialog loginStatus={loginStatus} updateLogin={updateLoginStatus}/> </ButtonAppBar>

      {/*<Grid container sx={{ position: "fixed", justifyContent:"space-between", p:2 }} >
        <Grid item>
          <Box sx={{ display: "flex", height: "100%" }}
            component="img"
            src={logo} />
        </Grid>
        <Grid item >
          <Box sx={{ display: "flex", height: "100%" }}>
            <UserDialog loginStatus={loginStatus} updateLogin={updateLoginStatus} />
          </Box>

        </Grid>
        {/*<Box sx={{ display: "flex", justifyContent: "start" }}>
          <Box
            component="img"
            src={logo}/>
        </Box>
        <Box sx={{display: "flex", justifyContent: "end" }}>
          <Button variant="contained">
            <AccountBoxOutlined fontSize={"large"} />
          </Button>
          <UserDialog loginStatus={loginStatus} updateLogin={updateLoginStatus} />
        </Box>*/}
        {/* <NavItem left={"1vw"}>
          <Box sx={{}}
               component="img"
               src={logo}
          />
        </NavItem>
        <NavItem right={"1vw"}>
          <UserDialog loginStatus={loginStatus} updateLogin={updateLoginStatus} />
        </NavItem>*/}
      {/*</Grid>*/}
      <Routes>
        {!submitted &&
        <Route exact path="/" element={
          (isHero ?
            <HeroForm
            loginStatus={loginStatus}
            setLoginStatus={setLoginStatus} />
              :
            <Form
            loginStatus={loginStatus}
            setLoginStatus={setLoginStatus} />
          )
        }
        />
        }
        {submitted &&
        <Route exact path="/" element={<Submitted />} />
        }
        <Route path="/confirm/:id" element={<Confirm />} />
        <Route path="/forgot-password/success" element={<ResetPasswordSuccess />} />
        <Route path="/forgot-password/expired" element={<ResetPasswordExpired />} />
        <Route path="/forgot-password/:id" element={<ResetPasswordForm />} />
        <Route path="/forgot-password" element={<ForgotPasswordConfirmation />} />
        {isHero &&<Route path="/about" element={<AboutUsHero />} />}
        <Route path="/error" element={<Error />} />
        <Route path="/thanks" element={<Submitted />} />
        <Route path="/404" element={<NotFound />} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
      {/*<Footer/>*/}

    </Box>
  );

}

export default App;
